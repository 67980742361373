import React from 'react';
import Select from 'react-select';

const MultiSelectDropdown = ({ field, form, options}) => {
  const handleChange = (selectedOptions) => {
    const value = selectedOptions ? selectedOptions.map(option => option.value) : [];
    form.setFieldValue(field.name, value);
  };

  const selectedOptions = options.filter(option =>
    field.value.includes(option.value)
  );

  return (
    <Select
      isMulti
      name={field.name}
      value={selectedOptions}
      onChange={handleChange}
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};

export default MultiSelectDropdown;
