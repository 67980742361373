import React from "react";
import { Row, Col, Container, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";


//img
// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const TermsandConditions = ({ handleNext }) => {
  const appName = useSelector(SettingSelector.app_name);

  return (
    <>
      <Col md={6}>
        <Card className="iq-accordion-block accordion-active p-0" style={{ backgroundColor: '#332B4F' }}>
          <Card.Header className="active-faq clearfix d-flex justify-content-between pb-0">
            <div className="header-title">
              <h4 className="card-title">Terms & Conditions</h4>
            </div>
          </Card.Header>
          <Card.Body className="accordion-details d-flex flex-column  gap-3 pb-0">
            <p className="mb-0 p-0">If your mobile app offers subscriptions, has an ecommerce component or has in-app purchases, you should include a clause that discusses the details of payments </p>
            <ul className="d-flex align-item-center flex-column gap-3">
              <li>
                If you are outside of the Designated Countries, LinkedIn Corporation will be the controller of your personal data provided to, or collected by or for, or processed in connection with, our Services.
              </li>

              <li>
                As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.
              </li>
              <li>
                If you are outside of the Designated Countries, LinkedIn Corporation will be the controller of your personal data provided to, or collected by or
              </li>

            </ul>
          <Button className="btn  btn-primaty mb-4" onClick={handleNext}>
            I Agree to all
          </Button>
          </Card.Body>
        </Card>
      </Col>

    </>
  );
};

export default TermsandConditions;
