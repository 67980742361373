import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup"
import * as SettingSelector from "../../../store/setting/selectors";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { AuthService } from '../../../services/AutService';

import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Resetpw = () => {
  const appName = useSelector(SettingSelector.app_name);
  let navigate = useNavigate();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [repasswordShown, setRepasswordShown] = useState(false);
  const passwordRegex =/^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
  const location = useLocation();
  const email = location.state?.email; 
  const otptocken = location.state?.otptocken; 
  const togglePasswordVisibility = (type) => {
    if (type === "password") { setPasswordShown(!passwordShown); }
    else { setRepasswordShown(!repasswordShown); }
  };

  const resetSchema = yup.object().shape({
    password: yup.string().matches(passwordRegex, "Invalid at least 8 characters, one special character, one capital letter").required("Please enter password"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], "Passwords must match").required("Please enter confirm password"),
  })



  const onSubmit = async (values) => {
    const userSignupData = {
      emailOrPhoneNumber:email,
      resetToken: otptocken,
      newPassword: values.password,
      confirmPassword: values.confirmPassword
    }
    setIsLoading(true);
    try {

      const response = await AuthService.resetPassword(userSignupData);
      setIsLoading(false);
      if (response.success) {
        toast.success("User registered successfully!", {
          position: "bottom-right"
        });
        navigate('/auth/sign-in');
      } else {
        toast.error(response.error, {
          position: "bottom-right"
        });
      }
    } catch (error) {
      setIsLoading(false);
      setError('Connection Error!');
    }

  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: resetSchema,
    onSubmit, // Fix the attribute name here
  });

  return (
    <>
      <section className="sign-in-page">
      <div>
          <ToastContainer />
        </div>
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Power UP Your Friendship
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Connect with the world
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Together Is better
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from">
                <Link
                  to={"/"}
                  className="d-inline-flex align-items-center justify-content-center gap-2"
                >
                  <h2 className="logo-title" data-setting="app_name">
                    {appName}
                  </h2>
                </Link>
                <p className="mt-3 font-size-16">
                  Enter your email address  or telephone number and we'll send you an email with
                  instructions to reset your password.
                </p>
                <Form className="mt-5" onSubmit={handleSubmit}>
                 
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label ">Password</h6>
                    <div className="input-group">
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        className="form-control mb-0"
                        name="password"
                        placeholder="password"
                        defaultValue=""
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}

                      />

                      <span
                        className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer "
                        onClick={() => togglePasswordVisibility("password")}
                      >
                        {passwordShown ? <FaEyeSlash /> : <FaEye />}
                      </span>

                    </div>
                    {touched.password && errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <h6 className="form-label">Confirm Password</h6>
                    <div className="input-group">
                      <Form.Control
                        type={repasswordShown ? "text" : "password"}
                        className="form-control mb-0"
                        placeholder="confirm password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span
                        className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                        onClick={() => togglePasswordVisibility("repassword")}
                      >
                        {repasswordShown ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <div className="text-danger">{errors.confirmPassword}</div>
                    )}
                  </Form.Group>
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase letter-spacing-1 w-100"
                  >
                   reset  Passward
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Resetpw;
