import React, { useState, useRef } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClose } from 'react-icons/md'; // Import the close icon


const AddVideo = ({ setVideo, video, uploadStatus }) => {
  // const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  // const [uploadStatus, setUploadStatus] = useState("");

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveVideo = () => {
    setVideo(null);
    setVideoPreview(null);
  };

  // const handlePost = async () => {
  //   if (video) {
  //     const formData = new FormData();
  //     formData.append('video', video);

  //     try {
  //       const response = await IndividualService.uploadVideo(formData);

  //       if (response.success) {
  //         setUploadStatus("Video uploaded successfully!");
  //       } else {
  //         setUploadStatus("Failed to upload video.");
  //       }
  //     } catch (error) {
  //       setUploadStatus("An error occurred during the upload.");
  //       console.error('Error uploading video:', error);
  //     }
  //   } else {
  //     setUploadStatus("No video file selected.");
  //   }
  // };

  return (
    <>
      <div className='d-flex justify-content-start'>
        <h5 className="card-title ">Add Self Video</h5>
      </div>
      <div id="post-modal-data">
        <div className="d-flex align-items-center pb-3"></div>
        <div className="mb-2">
          {!videoPreview ? (
            <div
              onClick={handleClick}
              style={{
                border: '2px dotted #000',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '10px',
              }}
            >
              <AiOutlineCloudUpload size={50} />
              <p>Drag and drop or click here to upload a video</p>
            </div>
          ) : (
          <div className="position-relative rounded overflow-hidden shadow-sm d-flex flex-column">
            <video width="70%" controls>
              <source src={videoPreview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button
              onClick={handleRemoveVideo}
              className="remove-btn position-absolute top-0 start-0 m-2 p-1 bg-light border-0 rounded-circle"
              style={{
                zIndex: 1,
              }}
            >
              <span className="material-symbols-outlined">delete</span>
            </button>
          </div>
          )}
          <input
            type="file"
            accept="video/*"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleVideoChange}
          />
        </div>
        {uploadStatus && <p>{uploadStatus}</p>}


      </div>
    </>
  );
};

export default AddVideo;
