import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useFormik } from "formik";
import * as yup from "yup"

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";
import { GoogleLogin } from '@react-oauth/google';
//img
// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
import logo from "../../../assets/images/icon.png";
import { AuthService } from '../../../services/AutService';

import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const appName = useSelector(SettingSelector.app_name);
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [emailOrPhone, setEmailOrPhone] = useState();

  console.log("email is:", emailOrPhone)
  console.log("password is:", password)

  let navigate = useNavigate();

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      console.log(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
    () => {
      const fetchUserProfileAndLogin = async () => {
        if (user) {
          try {
            // Fetch user profile information from Google
            const res = await axios.get(
              `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
              {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                  Accept: 'application/json',
                },
              }
            );

            setProfile(res.data);

            // Send authorization code to your backend for login
            const response = await AuthService.userGoogleLogin(user.code);

            if (response.success) {
              console.log("Login successful, navigating to home...");
              navigate('/onboard');
            } else {
              console.log("Login failed.");
            }
          } catch (err) {
            console.error("Error during profile fetch or login:", err);
          }
        }
      };

      fetchUserProfileAndLogin();
    }, [user, setProfile]);

  const signinSchema = yup.object().shape({
    // phoneNumber: yup.string().matches(americanPhoneRegExp, "Invalid American phone number").required("Required"),
    emailOrPhone: yup.string().required("Please enter email"),
    password: yup.string().required("Please enter password"),
  })




  const onSubmit = async (values, actions) => {

    const userLoginData = {
      password: values.password,
      emailOrPhone: values.emailOrPhone
    }

    const response = await AuthService.userLogin(userLoginData);

    if (response?.data?.token) {
      console.log("respons if success>>", response?.message)
      toast.success(response?.message || "You are logged in !", {
        position: "top-center",
      });
      console.log("--------------------");
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response?.data?.logedUser || {}));
      window.location.href = "/";
    } else {

      toast.error(response?.message || "falid to login!", {
        position: "bottom-right"
      });
    }
  }

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      emailOrPhone: "",
      password: "",
    },
    validationSchema: signinSchema,
    onSubmit, // Fix the attribute name here
  });


  return (
    <>
      <section className="sign-in-page">
        <div>
          <ToastContainer />
        </div>
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Power UP Your Friendship
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Connect with the world
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Together Is better
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from text-center">
                <div className="d-flex  flex-column">
                  <Link to={'/'}>
                    <h2 className="logo-title fs-1 d-flex" data-setting="app_name">
                      <span>
                        <img src={logo}  alt={appName} width={'45px'} height={'45px'}/>
                      </span> &nbsp; {appName}
                    </h2>
                  </Link>

                  <p className="mt-3 font-size-16 d-flex ">
                    For Friends and Dating
                  </p>
                </div>
                <Form className="mt-5" onSubmit={handleSubmit}>
                  <Form.Group className="form-group text-start">
                    <label className="form-label fw-500 text-dark">Email/Phone Number</label>
                    <Form.Control
                      type="text"
                      name="emailOrPhone"
                      className="form-control mb-0"
                      placeholder="Email/Phone Number"
                      value={values.emailOrPhone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.emailOrPhone && errors.emailOrPhone && (
                      <div className="text-danger">{errors.emailOrPhone}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <label className="form-label fw-500 text-dark">Password</label>
                    <Form.Control
                      type="password"
                      name="password"
                      className="form-control mb-0"
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}

                    />

                    {touched.password && errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                  >
                    Sign in
                  </Button>
                  <div className="d-flex justify-content-end mt-1">
                    <Link to="/auth/recoverpw" className="font-italic">
                      Forgot Password?
                    </Link>
                  </div>
                  <h6 className="mt-5" >
                    Don't Have An Account ?{" "}
                    <Link to="/auth/sign-up">Sign Up</Link>
                  </h6>
                </Form>
                <div className="or-divider mt-4 ">
                  <hr />
                  <span className="">OR</span>
                  <hr />
                </div>

                


                <FacebookLoginButton onClick={() => alert("We're working on it!. feature will be active soon.")} style={{ transform: "scale(1.0)" }} />

                <br></br>

                <GoogleLoginButton onClick={() => login()} style={{ transform: "scale(1.0)" }} />

                {/* <AppleLoginButton onClick={() => alert("Hello")} /> */}

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignIn;
