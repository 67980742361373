import React from "react";

//layoutpages
import Default from "../layouts/dashboard/default";


import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";
import { SimpleRouter } from './simple-router';
import  Authmiddleware  from './authmiddleware';
import Onbording from "../views/dashboard/onboarding/onboarding";


export const IndexRouters = [
  // {
  //   path: "/onboard",
  //   element: <Onbording/>,
  // },
  {
    path: "/",
    element: <Authmiddleware/>,
    children: [...DefaultRouter, ...Layout1Router,...SimpleRouter],
  },
];