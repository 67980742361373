import * as yup from "yup"

const nameRegExp = /^[a-zA-Z\s]*$/;
const americanPhoneRegExp = /^\d{1}?\d{9}$/;

export const introSchema = yup.object().shape({
  name: yup.string().matches(nameRegExp, "Only letters are allowed").required("First Name Required"),
  lastName: yup.string().matches(nameRegExp, "Only letters are allowed").required("Last Name Required"),
  state: yup.string() .required('State is required'),
  phoneNumber: yup.string().matches(americanPhoneRegExp, "Invalid phone number").required("Phone Number Required"),
  dob: yup.string().required("Date Of Birth Required"),
  gender: yup.string().required("Gender Required"),
})


export const moreintroSchema = yup.object().shape({
  moreInformation: yup.string().required("About me information Required"),
  aboutWho: yup.string().required("About who information Required"),
})