import React, { useState } from "react";
import { Row, Col, Container, Form, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";


// Redux Selector / Action
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';



// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const GetStart = ({ handleNext, handlePrevious }) => {
  // const appName = useSelector(SettingSelector.app_name);
  
  const handleNavigate = () => {
    console.log("clicked");
    window.location.href = '/';
  };

  return (
    <>



      <Col md={6}>
        <div className="sign-in-from">
          <div className=" d-flex flex-column align-items-center justify-content-center gap-3 pb-4">

            <Link
              // to="/onboard"
              className="d-inline-flex align-items-center justify-content-center gap-2"
            >

              {/* <svg width="25" height="24" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.832031 14.1962C0.832031 6.52974 7.2385 0.333252 14.9878 0.333252C22.7589 0.333252 29.1654 6.52974 29.1654 14.1962C29.1654 18.0594 27.7604 21.646 25.4479 24.6859C22.8967 28.0391 19.7523 30.9607 16.2129 33.254C15.4029 33.7839 14.6718 33.8239 13.7828 33.254C10.2233 30.9607 7.07884 28.0391 4.54953 24.6859C2.23534 21.646 0.832031 18.0594 0.832031 14.1962ZM10.3224 14.6278C10.3224 17.1961 12.4181 19.216 14.9878 19.216C17.5591 19.216 19.675 17.1961 19.675 14.6278C19.675 12.0796 17.5591 9.96131 14.9878 9.96131C12.4181 9.96131 10.3224 12.0796 10.3224 14.6278Z" fill="white" />
              </svg> */}


              <h4 className="logo-title text-center" data-setting="app_name ">
                {/* {appName} */}
                Awesome! your profile is ready
              </h4>
            </Link>
            <div className="d-flex flex-column  align-items-center justify-content-center ">

              <Button className="btn btn-primary" onClick={handleNavigate}>
                {/* <Link to="/dashboard/app/profile" className="btn btn-primary text-white text-decoration-none">   */}
                  Get Started
                {/* </Link> */}
              </Button>


            </div>
          </div>
          <div className="Getting Started"></div>



          <div className="py-4">
            <h4 className="logo-title text-left" data-setting="app_name ">
              {/* {appName} */}
              {/* What are your hobbies ? */}
            </h4>
          </div>




        </div>
      </Col>
      {/* <div className="d-flex  justify-content-between pt-4 px-4">
        <Button className="btn btn-primary "
          onClick={handleNext}>
          f
        </Button>
        <Button className="btn btn-primary "
          onClick={handleNext}>
          Next
        </Button>

      </div> */}
    </>
  );
};

export default GetStart;
