// optionsData.js
export const hobbyOptions = [
    { value: 'Acting', label: 'Acting' },
    { value: 'Beekeeping', label: 'Beekeeping' },
    { value: 'Board game', label: 'Board game' },
    { value: 'Creative writing', label: 'Creative writing' },
    { value: 'Crochet', label: 'Crochet' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Handicraft', label: 'Handicraft' },
    { value: 'Hiking', label: 'Hiking' },
    { value: 'Knitting', label: 'Knitting' },
    { value: 'Kite Surfing', label: 'Kite Surfing' },
    { value: 'Origami', label: 'Origami' },
    { value: 'Parachuting', label: 'Parachuting' },
    { value: 'Playing musical instrument', label: 'Playing musical instrument' },
    { value: 'Pottery', label: 'Pottery' },
    { value: 'Rock polishing', label: 'Rock polishing' },
    { value: 'Singing', label: 'Singing' },
    { value: 'Snowboarding', label: 'Snowboarding' },
    { value: 'Shopping', label: 'Shopping' },
    { value: 'Underwater diving', label: 'Underwater diving' },
    { value: 'Video Games', label: 'Video Games' },
    { value: 'Woodworking', label: 'Woodworking' }
];

export const states = [
    // { value: 'AA', label: 'AA' },
    // { value: 'AE', label: 'AE' },
    // { value: 'AP', label: 'AP' },
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: 'Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'District of Columbia', label: 'District of Columbia' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' }
];
