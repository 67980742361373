import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

const CropModal = ({ show, handleCancelModal, croppingImage, handleCropSave, setCropperRef }) => {
  return (
    <Modal show={show} onHide={handleCancelModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {croppingImage && (
          <Cropper
            src={croppingImage}
            onChange={(cropperRef) => setCropperRef(cropperRef)}
            className="cropper"
            aspectRatio={4 / 5}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleCropSave}>
          Save Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropModal;