import React from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'

const UserAccountSetting = () => {
    return (
        <>
            <div className="content-inner">
                <Container>
                    <Row>
                        <Col lg="4">
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-center">
                                    <div className="header-title">
                                        <h4 className="card-title">Per Month</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex align-items-baseline justify-content-center">
                                        <h1>$39.95/</h1> <h4>mon</h4>
                                    </div>
                                    <div className='pt-3 check-list'>
                                        <p>Video chats free up to 4 minutes and then 4.99 to replenish each time for an extra 4 minutes</p>
                                        <p>All texting is free</p>
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <Button type="submit" className="btn btn-primary me-2 ">Choose Plan</Button>{" "}
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-center">
                                    <div className="header-title">
                                        <h4 className="card-title">Three Month</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex align-items-baseline justify-content-center">
                                        <h1>$119.85/</h1> <h4>mon</h4>
                                    </div>
                                    <div className='pt-3 check-list'>
                                        <p>Video chats free up to 4 minutes and then 4.99 to replenish each time for an extra 4 minutes</p>
                                        <p>All texting is free</p>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Button type="submit" className="btn btn-primary me-2 ">Choose Plan</Button>{" "}
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="4">
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-center">
                                    <div className="header-title">
                                        <h4 className="card-title">Six Month</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex align-items-baseline justify-content-center">
                                        <h1>$39.95/</h1> <h4>mon</h4>
                                    </div>
                                    <div className='pt-3 check-list'>
                                        <p>Video chats free up to 4 minutes and then 4.99 to replenish each time for an extra 4 minutes</p>
                                        <p>All texting is free</p>
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <Button type="submit" className="btn btn-primary me-2 ">Choose Plan</Button>{" "}
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )

}

export default UserAccountSetting;