import { Navigate, Outlet } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { AuthService } from "../services/AutService"
import Default from "../layouts/dashboard/default"
import { useNavigate } from 'react-router-dom';
import Onbording from "../views/dashboard/onboarding/onboarding";

const Authmiddleware = () => {
  const [user, setUser] = useState()
  const [isLoading, setIsloading] = useState(true)
  let token = localStorage.getItem('token')
  const [isErr, setIsErr] = useState(false)

  useEffect(() => {


    AuthService.getMe()
      .then(res => {  
        if (res.success) {
          setUser(res.data.user)
          setIsErr(false)
        } else {
          setIsErr(true)
        }
        setIsloading(false)
      })
      .catch(error => {
        setIsloading(false)
      })
  }, [])

  if(!token) {
    console.log("[preference:",user?.compatiblePreference)
    return <Navigate to={{ pathname: "auth/sign-in" }}  replace />
  } else {
    if (!isLoading) {
      if (user?.compatiblePreference == "" ||user?.compatiblePreference == null ) {
        return  <Onbording/>
      } else {
        return <Default/>
      }
    } else {
      return <div className="d-flex justify-content-center align-items-center " style={{ height: "500px" }}>
     <p>Loading....</p>
    </div>
    }
  }
}

export default Authmiddleware
