import React from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Location = ({ handleNext, handlePrevious }) => {


  return (
    <>

      <Col md={12}>
        <div className="sign-in-from">
          <Link
            to="/onboard"
            className="d-inline-flex align-items-center justify-content-center gap-2"
          >
          <div>
            <h2 className="logo-title" data-setting="app_name">
              Location
            </h2>
          </div>
        </Link>

          <div class="form-group mt-3">
            <div>
              <GooglePlacesAutocomplete
                apiKey="AIzaSyANeQL0Flst_L_FU0SBEzJBivak0qsIDpY"
              />
            </div>
          </div>
          <Button className="btn btn-primary w-100 mt-4"
              onClick={handleNext}>
              Next
          </Button>
        </div>
      </Col>
    </>
  );
};

export default Location;
