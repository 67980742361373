import { Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { moreintroSchema } from "../schemas/introductionSchema";
import { IndividualService } from "../../../services/IndividualService";
import { useFormik } from "formik";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Aboutme = ({ handleNext, handlePrevious, formData }) => {
  // const appName = useSelector(SettingSelector.app_name);
  const onSubmit = async (values, actions) => {
    const submitData = {
      moreInformation: values.moreInformation,
      aboutWho: values.aboutWho,
    };

    await IndividualService.onboardupdate(submitData).then((res) => {

      if (res.success) {
        // actions.resetForm();
        handleNext(submitData);
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });

        console.log("error occured");
      }
    });
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      moreInformation: formData.moreInformation|| "",
      aboutWho: formData.aboutWho|| "",

    },
    validationSchema: moreintroSchema,
    onSubmit, // Fix the attribute name here
  });


  return (
    <>



      <Col md={12}>
        <div className="sign-in-from ">
          <div className=" d-inline-flex align-items-left justify-content-left pb-4">

            <Link
              className="d-inline-flex align-items-left justify-content-left gap-2"
            >
              <h2 className="logo-title text-left" data-setting="app_name ">
                More information
              </h2>
            </Link>
          </div>
          <div className="d-flex flex-column  align-items-left justify-content-center ">
            <div className="mb-3"></div>
            <h7 className="logo-title mb-1">About Me</h7>
            <div className="form-group">
              <textarea
                value={values.moreInformation}
                onChange={handleChange}
                id="moreInformation"
                type="text"
                onBlur={handleBlur}
                className={errors.moreInformation && touched.moreInformation ? "text-danger form-control border-primary" : "form-control border-primary"}
                name="moreInformation"
                rows="5"
                placeholder="about me..."
              ></textarea>

              {errors.moreInformation && touched.moreInformation && <p className="text-danger place-order-title">{errors.moreInformation}</p>}
            </div>

            <div className="mb-3"></div>
            <h7 className="logo-title mb-1">About who I am looking for</h7>
            <div className="form-group">
              <textarea
                value={values.aboutWho}
                onChange={handleChange}
                id="aboutWho"
                type="text"
                onBlur={handleBlur}
                className={errors.aboutWho && touched.aboutWho ? "text-danger form-control border-primary" : "form-control border-primary"}
                name="aboutWho"
                rows="5"
                placeholder="about who I am looking for..."
              ></textarea>
              {errors.aboutWho && touched.aboutWho && <p className="text-danger place-order-title">{errors.aboutWho}</p>}
            </div>
          </div>



          <div className="w-100 mt-4 justify-content-center text-center">
            <Button className="btn btn-primary w-100 mb-3" type="submit" onClick={handleSubmit}>
              Next
            </Button>
            
            <Link className="mt-2"
              onClick={handlePrevious}>
              Back
            </Link>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Aboutme;
