import React from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { AuthService } from '../../../services/AutService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';

import * as yup from "yup"
import { useNavigate } from 'react-router-dom';
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Otpvarify = () => {
  const appName = useSelector(SettingSelector.app_name);
  let navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; 

  const otpSchema = yup.object().shape({
    otp: yup.string().required("Please enter Otp"),
  })




  const onSubmit = async (values, actions) => {

    const RecoverData = {
        emailOrPhoneNumber:email,
        otp: values.otp
    }
     console.log(email);
     
    const res = await AuthService.verifyotp(RecoverData);
    if (res.success) {
       const existingState = location.state || {}; 
      navigate('/auth/resetpw', { state: {...existingState, otptocken: res.data.resetToken } });
    } else {
      toast.error(res?.message || "falid to login!", {
        position: "bottom-right"
      });
    }
  }

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
        otp: ""
    },
    validationSchema: otpSchema,
    onSubmit, // Fix the attribute name here
  });

  return (
    <>
      <section className="sign-in-page">
      <div>
          <ToastContainer />
        </div>
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Power UP Your Friendship
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Connect with the world
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Together Is better
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from">
                <Form className="mt-5"  onSubmit={handleSubmit}>
                  <Form.Group className="form-group text-start mb-0">
                    <h6 className="form-label fw-bold">Enter OTP here</h6>
                    <Form.Control
                      type="text"
                      className="form-control mb-0"
                      placeholder="OTP"
                      defaultValue=""
                      id="otp"
                      value={values.otp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                  {touched.otp && errors.otp && (
                      <div className="text-danger">{errors.otp}</div>
                    )}
                  <button
                    type="submit"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase letter-spacing-1"
                  >
                    Verify OTP
                  </button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Otpvarify;
