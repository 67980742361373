import React from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { IndividualService } from "../../../services/IndividualService";
import { useState } from "react";
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SendOtp = ({ handleNext, handlePrevious }) => {

  const [isLoading, setIsLoading] = useState(false);
  console.log("isloading", isLoading)

  const otpSchema = yup.object().shape({
    otp: yup.string().required("Please enter otp"),
  })

  const onSubmit = async (values, actions) => {
    const submitData = {
      otp: values.otp,
    };
    setIsLoading(true)
    await IndividualService.onboardOTP(submitData.otp).then((res) => {

      if (res.success) {
        actions.resetForm();
        handleNext();
        setIsLoading(false)
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });
        setIsLoading(false)

        console.log("error occured");
      }
    });
  };

  const resendOTP = async () => {
    await IndividualService.resendOTP().then((res) => {
      if (res.success) {
        toast.success('Successfully Sent the Otp Again!', {
          position: "bottom-right"
        });
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });

        console.log("error occured");
      }
    });
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpSchema,
    onSubmit, // Fix the attribute name here
  });

  return (
    <>

      <Col md={12}>
        <ToastContainer />
        <div className="sign-in-from">
          <Link
            to="/onboard"
            className="d-inline-flex align-items-center justify-content-center gap-2"
          >

            <h2 className="logo-title" data-setting="app_name">
              Enter OTP
            </h2>
          </Link>

          <Form className="mt-5">
            <Form.Group className="form-group text-start">
              <Form.Control
                value={values.otp}
                onChange={handleChange}
                id="otp"
                type="text"
                placeholder="Enter OTP"
                onBlur={handleBlur}
                className={errors.otp && touched.otp ? "text-danger form-control  mb-0" : "form-control  mb-0"}
              />
              {errors.otp && touched.otp && <p className="text-danger place-order-title">{errors.otp}</p>}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Link onClick={() => resendOTP()} className="font-italic">
                Resend OTP
              </Link>
            </div>
          </Form>
          <div className="w-100 mt-4 justify-content-center text-center">
            <Button className="btn btn-primary px-4 w-100 mt-4" type="submit" onClick={handleSubmit} >
              {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  <span className="ms-2">Submitting...</span>
                </>
              ) : (
                'Verify'
              )}
            </Button>

            <Link className="text-center" onClick={handlePrevious}>
              Back
            </Link>


          </div>
        </div>
      </Col>
    </>
  );
};

export default SendOtp;
