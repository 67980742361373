import React, { StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from "@react-oauth/google";

//router
import {  createBrowserRouter, RouterProvider } from 'react-router-dom'

//store

import { Provider } from 'react-redux';
//reducer
import {store} from './store'

import { IndexRouters } from './router';
import { PublicRouter } from './router/public-router';
import { ChatRouter } from './router/chat-router';
import { UserProvider } from './context/UserProvider';


const router = createBrowserRouter([
  ...PublicRouter,
  ...IndexRouters,
  ...ChatRouter
], { basename: process.env.PUBLIC_URL })
ReactDOM.createRoot(document.getElementById('root')).render(
  <GoogleOAuthProvider clientId='617378748328-me6tol072ouc5endi3qclta6ptvddk1u.apps.googleusercontent.com'>
  <StrictMode>
      <Provider store={store}>
        <App>
          <UserProvider>
          <RouterProvider router={router}>
          </RouterProvider>
          </UserProvider>
        </App>
      </Provider>
    </StrictMode>
  </GoogleOAuthProvider>
)

