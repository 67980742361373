import React, { useState, useContext } from 'react'

//router
import { Link, useLocation } from 'react-router-dom'

//react-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'


function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}

  const underDevelopment = () => {
    Swal.fire({
        title: 'This feature is under development.',
        text: 'Feature will be coming soon! ',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Got it!',
        // cancelButtonText: 'No, Activate Later'
      })
}

const VerticalNav = React.memo(() => {
    const [activeMenu, setActiveMenu] = useState(false)
    const [active, setActive] = useState('')
    //location
    let location = useLocation();
    // console.log(document);


    return (
        <React.Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">Main</span>
                        <span className="mini-icon" data-bs-toggle="tooltip" title="Social" data-bs-placement="right">-</span>
                    </Link>
                </li>
                <li className={`${location.pathname === '/' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/' ? 'active' : ''} nav-link `} aria-current="page" to="/">
                         <OverlayTrigger placement="right" overlay={<Tooltip>Profiles</Tooltip>}>
                        <i className="icon material-symbols-outlined">
                                edit
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Feed</span>
                    </Link>
                </li>
                <li className={`${location.pathname === '/dashboard/app/profile' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/dashboard/app/profile' ? 'active' : ''} nav-link `} aria-current="page"  to="/dashboard/app/profile">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Profiles</Tooltip>}>
                        <i className="icon material-symbols-outlined">
                                person
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Profile</span>
                    </Link>
                </li>
                
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/chat/friends' ? 'active' : ''} nav-link `} aria-current="page" to="#" onClick={() => underDevelopment()}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Chat</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                people
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Friend</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/chat/index' ? 'active' : ''} nav-link `} aria-current="page" to="#" onClick={() => underDevelopment()}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Chat</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                message
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Chat</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/app/notification' ? 'active' : ''} nav-link `} aria-current="page" to="#" onClick={() => underDevelopment()}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Notification</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                notifications
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Notification</span>
                    </Link>
                </Nav.Item>
                
            </Accordion>
        </React.Fragment >
    )
})

export default VerticalNav
