import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// Import selectors & action from setting store
// Redux Selector / Action


// import logo from "../../../assets/images/logo-full.png";
import oneMonth from "../../../assets/images/plans/per months.svg";
import threeMonth from "../../../assets/images/plans/three months.svg";

import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const underDevelopment = () => {
  Swal.fire({
      title: 'This feature is under development.',
      text: 'Please use skip option to continue. ',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Got it!',
      // cancelButtonText: 'No, Activate Later'
    })
}

const ActiveSubscription = ({ handleNext }) => {

  return (
    <div className="overflow-y-auto">
      <Row className="mt-1 justify-content-start">
        <Col  md={6} sm={12}>
          <div className="me-3 w-100" >
            <Card style={{ backgroundColor: "#332B4F" }} className="w-100">
              <Card.Body>
                <div className="plan-logo w-100 justify-content-center d-flex p-1">
                  <img src={oneMonth} alt="One month subscription" />
                </div>
                <div className="d-flex align-items-baseline justify-content-center classic-title">
                  <h1 className="classic-title">$39.99/</h1> <h4 className="classic-title">mon</h4>
                </div>
                <div className="pt-3 check-list classic-title ">
                  <p>Regular calls 5 minutes and video calling for up to 6 minutes once every month.</p>
                  <p>1.99 to replenish for another 6 minutes regular calling each time.</p>
                  <p>1 month length - auto renewal.</p>
                  <p>4.99 to replenish 5 minute video calling.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <Button className="mt-1 w-100" onClick={underDevelopment}>Select Plan</Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col  md={6} sm={12}>
          <div className="me-3 w-100" >
            <Card style={{ backgroundColor: "#332B4F" }}>
              <Card.Body>
                <div className="plan-logo w-100 justify-content-center d-flex p-1">
                  <img src={threeMonth} alt="Three month subscription" />
                </div>
                <div className="d-flex align-items-baseline justify-content-center classic-title">
                  <h1 className="classic-title">$119.99/</h1> <h4 className="classic-title">mon</h4>
                </div>
                <div className="pt-3 check-list classic-title">
                  <p>Regular calls for up to 18 minutes and 15 minutes free video calling each every 3 months.</p>
                  <p>3.99 to replenish for another 6 minutes regular calling each time.</p>
                  <p>3 month length - auto renewal.</p>
                  <p>6.99 to replenish 15 minute video calling.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <Button className="mt-1 w-100" onClick={underDevelopment}>Select Plan</Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col  md={6} sm={12}>
          <div className="me-3 w-100" >
            <Card style={{ backgroundColor: "#332B4F" }}>
              <Card.Body>
                <div className="plan-logo w-100 justify-content-center d-flex p-1">
                  <img src={oneMonth} alt="Six month subscription" />
                </div>
                <div className="d-flex align-items-baseline justify-content-center classic-title">
                  <h1 className="classic-title">$239.99/</h1> <h4 className="classic-title">mon</h4>
                </div>
                <div className="pt-3 check-list classic-title">
                  <p>Regular calls for up to 36 minutes and 30 minutes free video calling each every 6 months.</p>
                  <p>6.99 to replenish for another 6 minutes regular calling each time.</p>
                  <p>6 month length - auto renewal.</p>
                  <p>9.99 to replenish 30 minute video calling.</p>
                </div>
                <div className="d-flex justify-content-end">
                  <Button className="mt-1 w-100" onClick={underDevelopment}>Select Plan</Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
        <Row >
          <Col className="d-flex justify-content-end w-100 mr-4">
            <Link className="" onClick={handleNext}>
              Skip
            </Link>
          </Col>
        </Row>
    </div>
  );
};

export default ActiveSubscription;
