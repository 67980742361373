import axios from 'axios';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';

export interface UserLoginData {
  email: string;
  password: string;
}

export class AuthService {
  //keys
  private static readonly TOKEN_KEY = 'token';

  //log in
  public static async userLogin(
    userLoginData: UserLoginData
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('login');

    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      userLoginData
    );

    if (res.success) {
      console.log("--------------------");
      
      localStorage.setItem(AuthService.TOKEN_KEY, res.data.token); //TODO read token from cookie and remove this implementation
    }

    return res;
  }

    //frogott in
    public static async forgetPassword(
      emailOrPhoneNumber:string
    ): Promise<AppResponse<any>> {
      const ep = Util.apiPublicUrl('password/recover');
  
      const res = await axios.post<UserLoginData, AppResponse<any>>(
        ep,
        {emailOrPhoneNumber}
      );
  
      return res;
    }

  //log in
  public static async userGoogleLogin(
    idToken: string
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('signUpWithGoogleWeb');
    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      {
        "idToken": idToken,
        "medium": "WEB",
      }
    );

    if (res.success && res.data.token != null) {
      localStorage.setItem(AuthService.TOKEN_KEY, res.data.token); //TODO read token from cookie and remove this implementation
      console.log('-------------//////////////');
      
    }

    return res;
  }

  public static async userSignup(userSignupData: UserLoginData): Promise<AppResponse<any>> {

    const ep = Util.apiPublicUrl("register");
    const res = await axios.post<UserLoginData, AppResponse<any>>(ep, userSignupData);

    if (res.success && res.data.token != null) {
      localStorage.setItem(AuthService.TOKEN_KEY, res.data.token); //TODO read token from cookie and remove this implementation
      localStorage.setItem("data", JSON.stringify(userSignupData));
    }

    return res;
  }

  public static async resetPassword(
    resetdata:any
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('password/reset');
    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      resetdata
    );

    return res;
  }

  public static async verifyotp(
    resetdata:any
  ): Promise<AppResponse<any>> {
    const ep = Util.apiPublicUrl('password/verify-otp');

    const res = await axios.post<UserLoginData, AppResponse<any>>(
      ep,
      resetdata
    );

    return res;
  }



  public static async getMe(): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('self');

    const res = await axios.get<void, AppResponse<any>>(ep);

    console.log("----------00000000000");
    console.log(res);
    
    if (res.error) {
     // localStorage.removeItem(AuthService.TOKEN_KEY);
    }

    return res;
  }

  public static getToken(): string | null {
    return localStorage.getItem(AuthService.TOKEN_KEY); //TODO read token from cookie and remove this implementation
  }
}
