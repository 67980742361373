import React from "react";
import { Col, Form, Button, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { preferencesSchema } from "../schemas/preferenceSchema";
import { IndividualService } from "../../../services/IndividualService";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import MultiSelectDropdown from "../ui-kit/multiple-select"; // Import your MultiSelectDropdown component
import { Link } from "react-router-dom";

const ChosePreference = ({ handleNext, handlePrevious, formData }) => {


  const formik = useFormik({
    initialValues: {
      compatiblePreference: formData.compatiblePreference || "",
      height: 90,
      preferLanguages: formData.preferLanguages || [],
      pets: formData.pets || [],
      hobbies: formData.hobbies || [],
    },
    validationSchema: preferencesSchema,
    onSubmit: async (values, actions) => {
      const submitData = {
        compatiblePreference: values.compatiblePreference,
        height: values.height,
        preferLanguages: values.preferLanguages,
        pets: values.pets,
        hobbies: values.hobbies
      };

      const res = await IndividualService.onboardupdate(submitData);

      if (res.success) {
        actions.resetForm();
        handleNext(submitData);
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });
      }
    },
  });

  // Options for multi-select dropdown
  const languageOptions = [
    { value: 'english', label: 'English' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'french', label: 'French' },
    { value: 'russian', label: 'Russian' },
    { value: 'chinese', label: 'Chinese' }
  ];


  const petOptions = [
    { value: 'Butterflies', label: 'Butterflies' },
    { value: 'Canary', label: 'Canary' },
    { value: 'Chinchillas', label: 'Chinchillas' },
    { value: 'Cockatoos', label: 'Cockatoos' },
    { value: 'Ferrets', label: 'Ferrets' },
    { value: 'Fancy rats', label: 'Fancy rats' },
    { value: 'Goldfish', label: 'Goldfish' },
    { value: 'Guinea pigs', label: 'Guinea pigs' },
    { value: 'Hedgehogs', label: 'Hedgehogs' },
    { value: 'Hermit crabs', label: 'Hermit crabs' },
    { value: 'Horses', label: 'Horses' },
    { value: 'Lizards', label: 'Lizards' },
    { value: 'Praying Mantis', label: 'Praying Mantis' },
    { value: 'Skunks', label: 'Skunks' },
    { value: 'Snails', label: 'Snails' },
    { value: 'Snakes', label: 'Snakes' },
    { value: 'Tortoise', label: 'Tortoise' },
    { value: 'Turtles', label: 'Turtles' },
  ]

  const hobbyOptions = [
    { value: 'Acting', label: 'Acting' },
    { value: 'Beekeeping', label: 'Beekeeping' },
    { value: 'Board game', label: 'Board game' },
    { value: 'Creative writing', label: 'Creative writing' },
    { value: 'Crochet', label: 'Crochet' },
    { value: 'Dance', label: 'Dance' },
    { value: 'Handicraft', label: 'Handicraft' },
    { value: 'Hiking', label: 'Hiking' },
    { value: 'Knitting', label: 'Knitting' },
    { value: 'Kite Surfing', label: 'Kite Surfing' },
    { value: 'Origami', label: 'Origami' },
    { value: 'Parachuting', label: 'Parachuting' },
    { value: 'Playing musical instrument', label: 'Playing musical instrument' },
    { value: 'Pottery', label: 'Pottery' },
    { value: 'Rock polishing', label: 'Rock polishing' },
    { value: 'Singing', label: 'Singing' },
    { value: 'Snowboarding', label: 'Snowboarding' },
    { value: 'Shopping', label: 'Shopping' },
    { value: 'Underwater diving', label: 'Underwater diving' },
    { value: 'Video Games', label: 'Video Games' },
    { value: 'Woodworking', label: 'Woodworking' },
  ]

  return (
    <Col md={12}>
      <div className="sign-in-from">
        <h4 className="logo-title text-left mb-3">Choose Compatible Preference</h4>

        <Form onSubmit={formik.handleSubmit} className="form-label fw-500 text-dark">
          <div className="d-flex align-items-left justify-content-left ">
            <Form.Check
              type="radio"
              label="Male"
              name="compatiblePreference"
              value="male"
              checked={formik.values.compatiblePreference === "male"}
              onChange={formik.handleChange}
              inline
              className="custom-radio"
            />
            <Form.Check
              type="radio"
              label="Female"
              name="compatiblePreference"
              value="female"
              checked={formik.values.compatiblePreference === "female"}
              onChange={formik.handleChange}
              inline
              className="custom-radio"
            />
            <Form.Check
              type="radio"
              label="Anyone"
              name="compatiblePreference"
              value="anyone"
              checked={formik.values.compatiblePreference === "anyone"}
              onChange={formik.handleChange}
              inline
              className="custom-radio"
            />
          </div>
          {formik.errors.compatiblePreference && formik.touched.compatiblePreference && (
            <div className=" d-flex justify-content-start text-danger">{formik.errors.compatiblePreference}</div>
          )}

          <div className="py-4">
            <h4 className="logo-title text-left">What is your preferred language?</h4>
          </div>

          <MultiSelectDropdown
            field={{
              name: "preferLanguages",
              value: formik.values.preferLanguages
            }}
            form={formik}
            options={languageOptions}
          />
          {formik.errors.preferLanguages && formik.touched.preferLanguages && (
            <div className=" d-flex justify-content-start text-danger">{formik.errors.preferLanguages}</div>
          )}

          <div className="py-4">
            <h4 className="logo-title text-left">What pets do you have?</h4>
          </div>

          <MultiSelectDropdown
            field={{
              name: "pets",
              value: formik.values.pets
            }}
            form={formik}
            options={petOptions}
          />

          {formik.errors.pets && formik.touched.pets && (
            <div className=" d-flex justify-content-start text-danger">{formik.errors.pets}</div>
          )}


          <div className="py-4">
            <h4 className="logo-title text-left">What are your hobbies?</h4>
          </div>

          <MultiSelectDropdown
            field={{
              name: "hobbies",
              value: formik.values.hobbies
            }}
            form={formik}
            options={hobbyOptions}
          />

          {formik.errors.hobbies && formik.touched.hobbies && (
            <div className=" d-flex justify-content-start text-danger">{formik.errors.hobbies}</div>
          )}



          <div className="w-100 mt-4 justify-content-center text-center">
            <Button className="btn btn-primary w-100 mb-3" type="submit" disabled={formik.isSubmitting}>
              Next
            </Button>

            <Link className="text-center" onClick={handlePrevious}>
              Back
            </Link>
          </div>
        </Form>
      </div>
    </Col>
  );
};

export default ChosePreference;
