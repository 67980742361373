import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { preferencesSchema } from "../schemas/preferenceSchema";
import { IndividualService } from "../../../services/IndividualService";
import { useFormik } from "formik";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

import { hobbypetSchema } from "../schemas/preferenceSchema";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Pets = ({ handleNext, handlePrevious }) => {
  // const appName = useSelector(SettingSelector.app_name);
  const [hobbies, setHobbies] = useState([]);
  const [pets, setPets] = useState([]);

  const handleHobbiesToggle = (hobby) => {
    let updatedHobbies;

    if (hobbies.includes(hobby)) {
      updatedHobbies = hobbies.filter(hobb => hobb !== hobby);
    } else {
      updatedHobbies = [...hobbies, hobby];
    }

    setHobbies(updatedHobbies);
    setFieldValue('hobbies', updatedHobbies);
  };

  const handlePetToggle = (pet) => {
    let updatedPets;

    if (pets.includes(pet)) {
      updatedPets = pets.filter(existingPet => existingPet !== pet);
    } else {
      updatedPets = [...pets, pet];
    }

    setPets(updatedPets);
    setFieldValue('pets', updatedPets);
  };

  const onSubmit = async (values, actions) => {
    const submitData = {
      hobbies: values.hobbies,
      pets: values.pets
    };

    await IndividualService.onboardupdate(submitData).then((res) => {

      if (res.success) {
        actions.resetForm();
        handleNext();
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });

        console.log("error occured");
      }
    });
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      hobbies: [],
      pets: []
    },
    validationSchema: hobbypetSchema,
    onSubmit, // Fix the attribute name here
  });

  return (
    <>
      <Col md={6}>
        <div>
          <ToastContainer />
        </div>
        <div className="sign-in-from">
          <div className=" d-inline-flex align-items-left justify-content-left pb-4">

            <Link
              // to="/onboard"
              className="d-inline-flex align-items-left justify-content-left gap-2"
            >

              {/* <svg width="25" height="24" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.832031 14.1962C0.832031 6.52974 7.2385 0.333252 14.9878 0.333252C22.7589 0.333252 29.1654 6.52974 29.1654 14.1962C29.1654 18.0594 27.7604 21.646 25.4479 24.6859C22.8967 28.0391 19.7523 30.9607 16.2129 33.254C15.4029 33.7839 14.6718 33.8239 13.7828 33.254C10.2233 30.9607 7.07884 28.0391 4.54953 24.6859C2.23534 21.646 0.832031 18.0594 0.832031 14.1962ZM10.3224 14.6278C10.3224 17.1961 12.4181 19.216 14.9878 19.216C17.5591 19.216 19.675 17.1961 19.675 14.6278C19.675 12.0796 17.5591 9.96131 14.9878 9.96131C12.4181 9.96131 10.3224 12.0796 10.3224 14.6278Z" fill="white" />
              </svg> */}


              <h4 className="logo-title text-left" data-setting="app_name ">
                {/* {appName} */}
                What pets do you have?
              </h4>
            </Link>
          </div>


          <div className="d-flex flex-column  align-items-left justify-content-center ">

            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='dog'
                checked={pets.includes('dog')}
                onChange={() => handlePetToggle('dog')}
              />
              <Form.Check.Label>Dog</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='cat'
                checked={pets.includes('cat')}
                onChange={() => handlePetToggle('cat')}
              />
              <Form.Check.Label>Cat</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='amphibian'
                checked={pets.includes('amphibian')}
                onChange={() => handlePetToggle('amphibian')}
              />
              <Form.Check.Label>Amphibian</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='birds'
                checked={pets.includes('birds')}
                onChange={() => handlePetToggle('birds')}
              />
              <Form.Check.Label>Birds</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='mouse'
                checked={pets.includes('mouse')}
                onChange={() => handlePetToggle('mouse')}
              />
              <Form.Check.Label>Birds</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='rabbit'
                checked={pets.includes('rabbit')}
                onChange={() => handlePetToggle('rabbit')}
              />
              <Form.Check.Label>Rabbit</Form.Check.Label>
            </Form.Check>{" "}
            {errors.pets && touched.pets && <p className="text-danger place-order-title">{errors.pets}</p>}
          </div>



          <div className="py-4">
            <h4 className="logo-title text-left" data-setting="app_name ">
              {/* {appName} */}
              What are your hobbies ?
            </h4>
          </div>

          <div className="d-flex flex-column  align-items-left justify-content-center ">

            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='reading'
                checked={hobbies.includes('reading')}
                onChange={() => handleHobbiesToggle('reading')}
              />
              <Form.Check.Label>Reading</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='gardening'
                checked={hobbies.includes('gardening')}
                onChange={() => handleHobbiesToggle('gardening')}
              />
              <Form.Check.Label>Gardening</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='cooking'
                checked={hobbies.includes('cooking')}
                onChange={() => handleHobbiesToggle('cooking')}
              />
              <Form.Check.Label>Cooking</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='panting'
                checked={hobbies.includes('panting')}
                onChange={() => handleHobbiesToggle('panting')}
              />
              <Form.Check.Label>Panting</Form.Check.Label>
            </Form.Check>{" "}
            <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
              <Form.Check.Input
                type="checkbox"
                className="form-check-input bg-primary custom-checkbox"
                id='music'
                checked={hobbies.includes('music')}
                onChange={() => handleHobbiesToggle('music')}
              />
              <Form.Check.Label>Music</Form.Check.Label>
            </Form.Check>
            {errors.hobbies && touched.hobbies && <p className="text-danger place-order-title">{errors.hobbies}</p>}
          </div>


        </div>
        <Row className="my-5">
          <Col className="d-flex  justify-content-center">
            <Button className="btn btn-primary "
              onClick={handlePrevious}>
              Previouse
            </Button>
          </Col>
          <Col></Col>
          <Col className="d-flex  justify-content-center">
            <Button className="btn btn-primary " type="submit" onClick={handleSubmit}>
              Next
            </Button>
          </Col>
        </Row>
      </Col>
      <div className="d-flex  justify-content-between pt-4 px-4">



      </div>
    </>
  );
};

export default Pets;
