import  { createContext, useState } from 'react';

// Define the type for the User object
interface User {
  name: string;
  mode: string; // Mode can be 'Dating' or 'dark'
}

// Create the User Context
const UserContext = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}>({
  user: null, // Initial user is null
  setUser: () => {}, // Placeholder for setUser function
});
// Export the UserContext so it can be used in other components
export { UserContext };

// Create a Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<User | null>({
    name: 'John Doe',
    mode: 'Dating', // Default mode is 'Dating'
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};