import * as yup from "yup"


export const preferencesSchema = yup.object().shape({

  compatiblePreference: yup.string().required("Looking for is Required"),
  pets: yup.array().min(1, "At least one pet is required"),
  height: yup.number().required("Required"),
  preferLanguages: yup.array().min(1, "At least one language is required") // Ensures the array has at least one item
    .required("Languages are required"), // Ensures the array itself is required
  hobbies: yup.array().min(1, "At least one hobby is required") // Ensures the array has at least one item
    .required("hobbies are required"), // Ensures the array itself is required
})

export const hobbypetSchema = yup.object().shape({

  pets: yup.array().min(1, "At least one pet is required") // Ensures the array has at least one item
    .required("pet are required"), // Ensures the array itself is required
  hobbies: yup.array().min(1, "At least one hobby is required") // Ensures the array has at least one item
    .required("hobbies are required"), // Ensures the array itself is required

})