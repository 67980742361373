import React, { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as yup from "yup"
//img
// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
import logo from "../../../assets/images/icon.png";

import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";
import { AuthService, UserLoginData } from '../../../services/AutService';
// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);




const SignUp = () => {
  const appName = useSelector(SettingSelector.app_name);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [repasswordShown, setRepasswordShown] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState();
  console.log("email>>")
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isChecked, setIsChecked] = useState(false);
  let navigate = useNavigate();

  console.log("error is:", error)
  const togglePasswordVisibility = (type) => {
    if (type === "password") { setPasswordShown(!passwordShown); }
    else { setRepasswordShown(!repasswordShown); }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const signUpSchema = yup.object().shape({
    // phoneNumber: yup.string().matches(americanPhoneRegExp, "Invalid American phone number").required("Required"),
    email: yup.string().required("Please enter email"),
    password: yup.string().required("Please enter password"),
    confirmPassword: yup.string().required("Please enter confirm password"),
  })



  const onSubmit = async (values) => {

    const userSignupData = {
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword
    }
    setIsLoading(true);
    try {

      const response = await AuthService.userSignup(userSignupData);
      setIsLoading(false);
      if (response.success && response.data.role != 'SUPER_ADMIN') {
        toast.success("User registered successfully!", {
          position: "bottom-right"
        });
        navigate('/');
      } else if (!response.success) {
        if (typeof response.error == 'string') {
          toast.error(response?.message, {
            position: "bottom-right"
          });

          console.log(">>", response?.message)
        } else {
          toast.error("connection error", {
            position: "bottom-right"
          });
        }
      } else {
        toast.error("Not Authorized", {
          position: "bottom-right"
        });
      }
    } catch (error) {
      setIsLoading(false);
      setError('Connection Error!');
    }

  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: signUpSchema,
    onSubmit, // Fix the attribute name here
  });



  return (
    <>
      <section className="sign-in-page">
        <div>
          <ToastContainer />
        </div>
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Power UP Your Friendship
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <div>
                    </div>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Connect with the world
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Together Is better
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <div className="sign-in-from ">
                <div className="d-flex  flex-column">
                  <Link to={'/'}>
                    <h2 className="logo-title fs-1 d-flex" data-setting="app_name">
                      <span>
                        <img src={logo}  alt={appName} width={'45px'} height={'45px'}/>
                      </span> &nbsp; {appName}
                    </h2>
                  </Link>

                  <p className="mt-3 font-size-16 d-flex ">
                  Welcome to Compatible1, a platform to connect with
                  the social world
                </p>
                </div>
                <Form className="mt-5" onSubmit={handleSubmit}>
                  <Form.Group className="form-group text-start">
                    <label className="form-label fw-500 text-dark">Email </label>
                    <Form.Control
                      type="email"
                      name="email"
                      className="form-control mb-0"
                      placeholder="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}

                    />
                    {touched.email && errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group fw-semibold text-start">
                    <label className="form-label fw-500 text-dark">Password</label>
                    <div className="input-group">
                      <Form.Control
                        type={passwordShown ? "text" : "password"}
                        className="form-control mb-0"
                        name="password"
                        placeholder="password"
                        defaultValue=""
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}

                      />

                      <span
                        className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer "
                        onClick={() => togglePasswordVisibility("password")}
                      >
                        {passwordShown ? <FaEyeSlash /> : <FaEye />}
                      </span>

                    </div>
                    {touched.password && errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </Form.Group>
                  <Form.Group className="form-group text-start">
                    <label className="form-label fw-500 text-dark">Confirm Password</label>
                    <div className="input-group">
                      <Form.Control
                        type={repasswordShown ? "text" : "password"}
                        className="form-control mb-0"
                        placeholder="confirm password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {/* <div className="input-group-append-custom">
                        <Button
                          variant="form-control outline-secondary"
                          className="input-group-text-custom"
                          onClick={() => togglePasswordVisibility("repassword")}
                        >
                          {repasswordShown ? <FaEyeSlash /> : <FaEye />}
                        </Button>
                      </div> */}
                      <span
                        className="position-absolute end-0 top-50 translate-middle-y me-3 cursor-pointer"
                        onClick={() => togglePasswordVisibility("repassword")}
                      >
                        {repasswordShown ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <div className="text-danger">{errors.confirmPassword}</div>
                    )}
                  </Form.Group>
                  <div className="d-flex align-items-center justify-content-between">
                    <Form.Check className="form-check d-inline-block m-0">
                      <Form.Check.Input
                        type="checkbox"
                        className="form-check-input"
                        defaultChecked={isChecked}
                        onClick={handleCheckboxChange}

                      />
                      <h6 className="form-check-label fw-500 font-size-14">
                        I accept{" "}
                        <Link className="fw-light ms-1" target="_blank" to="https://compatible1.com/terms-and-conditions.html">
                          Terms and Conditions
                        </Link>
                      </h6>
                    </Form.Check>
                  </div>
                  <Button
                    type="submit"
                    variant="primary"
                    className="btn btn-primary mt-4 fw-semibold text-uppercase w-100"
                    disabled={!isChecked}

                  >
                    Sign Up
                  </Button>
                  <h6 className="mt-5 d-flex justify-content-center" >
                    Already Have An Account ?{" "}
                    <Link to={"/auth/sign-in"}>Login</Link>
                  </h6>
                </Form>
                <div className="or-divider mt-4 ">
                  <hr />
                  <span className="">OR</span>
                  <hr />
                </div>

                


                <FacebookLoginButton onClick={() => alert("We're working on it!. feature will be active soon.")} style={{ transform: "scale(1.0)" }} />

                <br></br>

                <GoogleLoginButton onClick={() => alert("We're working on it!. feature will be active soon.")} style={{ transform: "scale(1.0)" }} />

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignUp;
