import React, { useState } from "react";
import { Row, Col, Container, Form, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";

//img
// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";
import AddPhotos from "../../../components/add-Photos";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const UploadPhoto = ({ handleNext, handlePrevious }) => {

  const uploadAssesets = () => {
    console.log("testing...")
  }
  
  return (
    <>
      <Col md={6}>
        <div className="sign-in-from ">
          <div className=" d-inline-flex align-items-left justify-content-left pb-4">
          </div>
          <div className="d-flex flex-column  align-items-left justify-content-center ">
            <AddPhotos />
          </div>

        </div>
        <Row>
          <Col className="d-flex justify-content-end" style={{ marginRight: '30px' }}>
            <Button className="btn btn-primary "
              onClick={handlePrevious}>
              Previouse
            </Button>
          </Col>
          <Col></Col>
          <Col className="d-flex justify-content-center">
            <Button className="btn btn-primary "
              onClick={() => {
                handleNext();
                uploadAssesets()
              }} >
              Next
            </Button>

          </Col>
        </Row>
      </Col>
      <div className="d-flex  justify-content-between px-4">


      </div>
    </>
  );
};

export default UploadPhoto;
