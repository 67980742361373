import React, { useRef, useState } from "react";
import {  toast } from 'react-toastify';
//router
import { Link } from "react-router-dom";

//react bootstrap
import { Dropdown, Button, Modal } from "react-bootstrap";

//components
import CustomToggle from "./dropdowns";

//images
import user1 from "../assets/images/user/1.jpg";
import { IndividualService } from "../services/IndividualService";
import '../../src/assets/styles/createPost.css'; // Import the CSS file here
import CropModal from "./common/cropModal";

import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'

// import small1 from "../assets/images/small/07.png";
// import small2 from "../assets/images/small/08.png";
// import small3 from "../assets/images/small/09.png";
// import small4 from "../assets/images/small/10.png";
// import small5 from "../assets/images/small/11.png";
// import small6 from "../assets/images/small/12.png";
// import small7 from "../assets/images/small/13.png";
// import small8 from "../assets/images/small/14.png";

const CreatePost = (props) => {
  const fileInputRef = useRef(null);
  const [show, setShow] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [photoPreviews, setPhotoPreviews] = useState([]); // Store the preview URLs
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [content, setContent] = useState('');
  const [privacy, setPrivacy] = useState('Everyone'); 
  const [showUploadImage, setShowUploadImage] = useState(false);

  const [cropModalVisible, setCropModalVisible] = useState(false); // To c

  const [showModal, setShowModal] = useState(false);
  const [croppingImage, setCroppingImage] = useState(null); // For the image to crop
  const [cropperRef, setCropperRef] = useState(null);

  const [isPosting, setIsPosting] = useState(false); // State to track if the button is disabled
  const [temporaryImage, setTemporaryImage] = useState(null); // To hold the image for cropping before confirmation

  const handleIconClick = (e) => {
    e.preventDefault(); 
    if (fileInputRef.current) {
      fileInputRef.current.click(); 
    }
  };

const handleFileChange = (e) => {
  const files = Array.from(e.target.files);
  if (files.length > 0) {
    const file = files[0]; 
    const previewUrl = URL.createObjectURL(file);
    setTemporaryImage(file); 
    setCroppingImage(previewUrl); 
    setShowModal(true); 
  }
};

  const handleRemovePhoto = (index) => {
    setPhotoPreviews((prevPhotos) => prevPhotos.filter((_, i) => i !== index)); 
  setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };


  const handleContentChange = (e) => {
    setContent(e.target.value); 
  };

  const handlePrivacyChange = (e) => {
    setPrivacy(e.target.value);
  };

  

    const handleEditPhoto = (index) => {
      const originalImage = photos[index];
      const imageUrl = URL.createObjectURL(originalImage);
      setTemporaryImage(index); 
      setCroppingImage(imageUrl); 
      setShowModal(true); 
    };

  const handleCreatePost = async () => {
    if (!content && photos.length === 0) return; 
    setIsPosting(true); 
    const formData = new FormData();
    
    // Append content and privacy to FormData
    formData.append("content", content);
    formData.append("privacy", privacy);

    // Append each image file (as actual File objects, not Blob URLs)
    photos.forEach((image, index) => {
      formData.append("images", image); // Pass the File object, not Blob URL
    });

 
    const response = await IndividualService.createPost(formData);

    try {
    if (response.success) {
      console.log('Post created successfully!');
    setContent('');
    setPhotos([]); 
    setPhotoPreviews([]);
    toast.success(response?.message || "Post created successfully!", {
      position: "top-center",
    });
       setTimeout(() => {
        setIsPosting(false);
      }, 1000); // 1 second delay
    } else {
      if(response.error == "No active subscription found!"){
        Swal.fire({
          title: 'No Active Subcription Plan',
          text: 'Do You want to Subscribe a Compatible1! ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Activate It',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire(
              'Sorry!',
              'This feature is under development.',
              'error'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Subscription is not completed',
              'error'
            )
          }
        })
      }else{
      toast.error(response.error, {
        position: "bottom-right",
      });

      setTimeout(() => {
        setIsPosting(false);
      }, 1000); 
    }
  }
    }
    catch (error) {
      console.error('An error occurred:', error);
      toast.error("An error occurred while creating the post", {
        position: "bottom-right",
      });
  
  };
  setTimeout(() => {
    setIsPosting(false);
  }, 1000); 
}


  const renderImagePreviews = () => {
    const photoCount = photoPreviews.length;

    // Apply different layouts for different numbers of images
    if (photoCount === 1) {
      return (
        <div className="single-photo-container">
          <img
            src={photoPreviews[0]}
            alt="Preview"
            className="img-fluid rounded"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
           <button
                type="button"
                onClick={() => handleRemovePhoto(0)}
                className="remove-btn"
              >
                 <span className="material-symbols-outlined">close</span>
              </button>
               {/* Edit Button */}
               <button
                type="button"
                onClick={() => handleEditPhoto(0)}
                className="edit-btn"
              >
                <span className="material-symbols-outlined">crop</span>
               
              </button>
        </div>
      );
    } else if (photoCount === 2) {
      return (
        <div className="double-photo-container d-flex">
          {photoPreviews.map((photoPreview, index) => (
            <div key={index} className="photo-half">
              <img
                src={photoPreview}
                alt={`Preview ${index}`}
                className="img-fluid rounded"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <button
                type="button"
                onClick={() => handleRemovePhoto(index)}
                className="remove-btn"
              >
                 <span className="material-symbols-outlined">close</span>               
              </button>
               {/* Edit Button */}
               <button
                type="button"
                onClick={() => handleEditPhoto(index)}
                className="edit-btn"
              >
                <span className="material-symbols-outlined">crop</span>
              </button>
            </div>
          ))}
        </div>
      );
    } else if (photoCount > 2) {
      return (
        <div className="multiple-photo-container">
          {photoPreviews.map((photoPreview, index) => (
            <div key={index} className="grid-photo">
              <img
                src={photoPreview}
                alt={`Preview ${index}`}
                className="img-fluid rounded"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <button
                type="button"
                onClick={() => handleRemovePhoto(index)}
                className="remove-btn"
              >
              <span className="material-symbols-outlined">close</span>
              </button>

               {/* Edit Button */}
               <button
                type="button"
                onClick={() => handleEditPhoto(index)}
                className="edit-btn"
              >
              <span className="material-symbols-outlined">crop</span>

              </button>
            </div>
          ))}
        </div>
      );
    }
  };


// Cancel the crop modal and discard the temporary image if it's a new unsaved image
const handleCancelModal = () => {
  if (temporaryImage && typeof temporaryImage !== 'number') {

    setTemporaryImage(null); 
  }

  setCroppingImage(null);
  setShowModal(false);
};
  const handleCropSave = () => {
    if (cropperRef) {
      const croppedCanvas = cropperRef.getCanvas();
      if (croppedCanvas) {
        croppedCanvas.toBlob((blob) => {
          if (blob) {
            if (typeof temporaryImage === 'number') {
              // Edit an existing image
              const index = temporaryImage;
              setPhotos((prevPhotos) => {
                const updatedFiles = [...prevPhotos];
                updatedFiles[index] = blob; // Replace existing image
                return updatedFiles;
              });
              setPhotoPreviews((prevPreviews) => {
                const updatedPreviews = [...prevPreviews];
                updatedPreviews[index] = URL.createObjectURL(blob); // Update preview
                return updatedPreviews;
              });
            } else {
              // Add a new cropped image
              setPhotos((prevFiles) => [...prevFiles, blob]);
              setPhotoPreviews((prevPreviews) => [...prevPreviews, URL.createObjectURL(blob)]);
              setTemporaryImage(null); // Clear temporary image after saving
            }
            setCroppingImage(null);
            setShowModal(false); 
          }
        });
      }
    }
  };


  return (
    <>
      <div id="post-modal-data" className={`card ${props.class}`}>
        <div className="card-header d-flex justify-content-between border-bottom">
          <div className="header-title">
            <h5 className="card-title">Create a Post</h5>
          </div>
          
        </div>
        <div className="card-body">
        <div className="d-flex align-items-center mb-3">
            <label htmlFor="privacy" className="me-2 fw-medium">Privacy:</label>
              <select
                id="privacy"
                className="form-select w-auto"
                value={privacy}
                onChange={handlePrivacyChange} 
              >
                <option value="Everyone">Everyone</option>
                <option value="Friends">Friends</option>
                <option value="Only Me">Only Me</option>
              </select>
          </div>
          <div className="d-flex align-items-center mb-5">
            <form className="post-text w-100" 
            // onClick={handleShow}
            >
              <input
                type="text"
                className="form-control rounded px-0"
                placeholder="Write And Share Your Post With Your Friends..."
                style={{ border: "none" }}
                value={content}
                onChange={handleContentChange}
              />
            </form>
          </div>
     {/* Render image previews dynamically */}
     {photoPreviews.length > 0 && renderImagePreviews()}
        </div>
        <div className="card-body bg-primary-subtle rounded-bottom-3">
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
            <div className="create-post-data">
              <ul className="list-inline m-0 p-0 d-flex align-items-center gap-4">
                <li>
                  <Link to="#" className="text-body" onClick={handleIconClick}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.5334 1.3761C12.3751 1.7111 12.6326 2.87776 12.9767 3.25276C13.3209 3.62776 13.8134 3.75526 14.0859 3.75526C15.5342 3.75526 16.7084 4.92943 16.7084 6.37693V11.2061C16.7084 13.1478 15.1334 14.7228 13.1917 14.7228H4.80841C2.86591 14.7228 1.29175 13.1478 1.29175 11.2061V6.37693C1.29175 4.92943 2.46591 3.75526 3.91425 3.75526C4.18591 3.75526 4.67841 3.62776 5.02341 3.25276C5.36758 2.87776 5.62425 1.7111 6.46591 1.3761C7.30841 1.0411 10.6917 1.0411 11.5334 1.3761Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.5794 5.91667H13.5869"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.6489 8.94C11.6489 7.47667 10.4631 6.29083 8.99975 6.29083C7.53642 6.29083 6.35059 7.47667 6.35059 8.94C6.35059 10.4033 7.53642 11.5892 8.99975 11.5892C10.4631 11.5892 11.6489 10.4033 11.6489 8.94Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>            
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }} 
                  accept="image/*"
                  onChange={handleFileChange} 
                />
                </li>
                <li>
                  {/* <Link to="#" className="text-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.23043 11.6718C4.02709 11.6718 1.29126 12.156 1.29126 14.096C1.29126 16.036 4.01043 16.5377 7.23043 16.5377C10.4346 16.5377 13.1696 16.0527 13.1696 14.1135C13.1696 12.1743 10.4513 11.6718 7.23043 11.6718Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.23042 8.90493C9.33292 8.90493 11.0371 7.20076 11.0371 5.09826C11.0371 2.99576 9.33292 1.2916 7.23042 1.2916C5.12875 1.2916 3.42459 2.99576 3.42459 5.09826C3.41709 7.19326 5.10875 8.89743 7.20459 8.90493H7.23042Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.0031 6.22427V9.56594"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.7079 7.895H13.2996"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link> */}
                </li>
                <li>
                  <Link to="#" className="text-body">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                    >
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="list-inline m-0 p-0 d-flex align-items-center gap-4">
                <li>
                  <Link to="#" className="text-body fw-medium">
                    Discard
                  </Link>
                </li>
                <li>
                  <button type="button" className="btn btn-primary px-4"
                  onClick={handleCreatePost}
                  disabled={(!content && photos.length === 0) || isPosting} // Disable if no content/photos or while posting
                  >
                    {isPosting ? 'Posting...' : 'Post'}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <CropModal
        show={showModal}
        handleCancelModal={handleCancelModal}
        croppingImage={croppingImage}
        handleCropSave={handleCropSave}
        setCropperRef={setCropperRef}
      />
      </div>
    </>
  );
};

export default CreatePost;
