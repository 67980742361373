import React, { useState } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";



import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

import Addvideo from "../../../components/add-Video";
import AddPhotos from "../../../components/add-Photos";
import { IndividualService } from '../../../services/IndividualService';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';



SwiperCore.use([Navigation, Autoplay]);

const UploadVideo = ({ handleNext, handlePrevious }) => {
  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loadingState, setLoadingState] = useState({ photos: false, video: false });

  const handlePhotoUpload = async () => {
    setLoadingState({ ...loadingState, photos: true });

    if (video) {
      const videoDuration = await getVideoDuration(video);
      console.log("Video duration:", videoDuration);

      if (videoDuration > 119) { // 60 minutes
        toast.error("Video length should not exceed 60 minutes.", { position: "bottom-right" });
        setLoadingState({ ...loadingState, video: false });
        return;
      }
    } else {
      toast.error("No video file selected.", { position: "bottom-right" });
      setLoadingState({ ...loadingState, video: false });
    }

    if (photos.length < 2) {
      toast.error("Please upload at least two photos.", { position: "bottom-right" });
      setLoadingState({ ...loadingState, photos: false });
      return;
    }

    const formData = new FormData();
    photos.forEach(file => formData.append('images', file));

    try {
      const response = await IndividualService.uploadPhotos(formData);
      setLoadingState({ ...loadingState, photos: false });

      if (response.success) {
        console.log("Photos uploaded successfully!");
      } else {
        toast.error("Photo uploading failed.", { position: "bottom-right" });
      }
    } catch (error) {
      console.error('Error uploading photos:', error);
      toast.error("Error uploading photos.", { position: "bottom-right" });
      setLoadingState({ ...loadingState, photos: false });
    }

    if (!video) {
      toast.error("No video file selected.", { position: "bottom-right" });
    } else {
      handleVideoPost();
    }
  };

  const handleVideoPost = async () => {
    setLoadingState({ ...loadingState, video: true });


    const videoDuration = await getVideoDuration(video);
    console.log("Video duration:", videoDuration);

    if (videoDuration > 119) {
      toast.error("Video length should not exceed 60 seconds.", { position: "bottom-right" });
      setLoadingState({ ...loadingState, video: false });
      return;
    }

    const formData = new FormData();
    formData.append('video', video);

    try {
      const response = await IndividualService.uploadVideo(formData);
      setLoadingState({ ...loadingState, video: false });

      if (response.success) {
        setUploadStatus("Video uploaded successfully!");
        console.log("Video response", response);
        handleNext();
      } else {
        setUploadStatus("Failed to upload video.");
        toast.error(response.error, { position: "bottom-right" });
      }
    } catch (error) {
      console.error('Error uploading video:', error);
      toast.error("Error uploading video.", { position: "bottom-right" });
      setLoadingState({ ...loadingState, video: false });
    }

  };

  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';

      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src);
        resolve(videoElement.duration);
      };

      videoElement.src = URL.createObjectURL(file);
    });
  };

  return (
    <Col md={12}>
      <ToastContainer />
      <div className="sign-in-from">
        <AddPhotos setPhotos={setPhotos} photos={photos} />
        <Addvideo setVideo={setVideo} video={video} uploadStatus={uploadStatus} />
        <div className="w-100 mt-4 justify-content-center text-center">
          <Button className="btn btn-primary px-4 w-100 mt-4" type="submit" onClick={handlePhotoUpload}>
            {loadingState.photos || loadingState.video ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Submitting...</span>
              </>
            ) : (
              'Next'
            )}
          </Button>
          <Link className="text-center" onClick={handlePrevious}>
            Back
          </Link>
        </div>
      </div>
    </Col>
  );
};


export default UploadVideo;
