import React from "react";
import { Row, Col, Container, Card, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// Import selectors & action from setting store
import * as SettingSelector from "../../../store/setting/selectors";
// Redux Selector / Action
import { useSelector } from "react-redux";
import datingImage from "../../../assets/images/datingandfriendship.png";


//img
// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const DatingandFriendships = ({handleNext}) => {
  const appName = useSelector(SettingSelector.app_name);

  return (
    <>




      <Row>
        <Col md={3}>
        </Col>
        <Col md={6}>
          <Card className="iq-accordion-block accordion-active p-0" style={{ backgroundColor: '#332B4F' }}>
            <Card.Header className="active-faq clearfix d-flex justify-content-center pb-0">
              <div className="header-title">
                <h4 className="card-title">Dating And Friendship</h4>
              </div>
            </Card.Header>
            <Card.Body className="accordion-details d-flex flex-column  gap-3 pb-4">
              <img src={datingImage} alt="dating and friendship image"></img>
              <p className="mb-0 p-0">
                Join a community of like-minded people searching for meaningful connections. Swipe,
                chat, and discover your perfect match with our user-friendly platform.
                It’s time to start your romantic and friendship journey – find love, laughter, and a lifetime of happiness right here!
              </p>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
        </Col>
      </Row>
      <div className="d-flex  justify-content-end pt-4 px-4">
        <Button className="btn btn-primary "
          onClick={handleNext}>
          Next
        </Button>

      </div>


    </>
  );
};

export default DatingandFriendships;
