import axios from 'axios';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';
import { OnboardDetails } from '../models/Onboarding';

export class IndividualService {

  public static async onboardDetails(
    onboardDetails: OnboardDetails
  ): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('introduce-self/');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      ep,
      onboardDetails
    );
    return res;
  }

  public static async onboardOTP(
    otp: string, phoneNumber: String
  ): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('introduce/verify-otp');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      ep,
      {
        "phoneNumber": phoneNumber,
        "otp": otp,
      }
    );
    return res;
  }


  public static async resendOTP(): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('introduce-self/resend-otp');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      ep,
      {}
    );
    return res;
  }

  public static async onboardupdate(
    onboardDetails: any
  ): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl('onboarding/individual/lookingFor');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      ep,
      onboardDetails
    );
    return res;
  }

  public static async uploadVideo(
    videofile: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('onboarding/individual/uploadVideo');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      url,
      videofile
    );
    return res;
  }


  public static async getpostlist(userRole: String): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`post/get-Posts/${userRole}/0/10`);
    const res = await axios.get<any, AppResponse<any>>(
      ep
    );
    return res;
  }

  public static async uploadPhotos(
    photofile: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('onboarding/individual/uploadPhotos');
    const res = await axios.post<OnboardDetails, AppResponse<any>>(
      url,
      photofile
    );
    return res;
  }

  public static async uploadProfilePhoto(
    photofile: FormData
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl('user/updateProfileImg');

    try {
      // Use axios post with multipart form data
      const res = await axios.post<any, AppResponse<any>>(
        url,
        photofile,
        {
          headers: {
            'Content-Type': 'multipart/form-data',  // Set Content-Type explicitly
          },
        }
      );
      return res;
    } catch (error) {
      console.error("Error uploading profile photo:", error);
      throw error;
    }
  }

  public static async likePost(postId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`like-post/${postId}`);
    const res = await axios.post<any, AppResponse<any>>(url);
    return res;
  }

  public static async postComment(postId: any, comment: string): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`postcomment`);
    const res = await axios.post<any, AppResponse<any>>(url, { postId, comment });
    return res;
  }
  public static async getCommentsList(postId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-comments/${postId}/10/0`);
    const res = await axios.get<any, AppResponse<any>>(url);
    return res;
  }

  public static async createPost(formData: FormData): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`post/create-post`);
    // Make the POST request using axios and pass the FormData
    const res = await axios.post<any, AppResponse<any>>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct content type is set
      },
    });

    return res;
  }
  public static async sharePost(postId: any, friendId: any): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`post/share-Posts`);
    const res = await axios.post<any, AppResponse<any>>(url, { postId, friendId });
    return res;
  }

  public static async getFriendList(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`friends/0/10`);
    const res = await axios.get<any, AppResponse<any>>(url);
    return res;
  }

  public static async getCompatibleFavoritesList(): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`friends/compatible-favourites/0/10`);
    const res = await axios.get<any, AppResponse<any>>(url);
    return res;
  }

  public static async getMyPost(userRole: String): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`post/get-My-Posts/${userRole}/:0/:10`);
    const res = await axios.get<any, AppResponse<any>>(url);
    return res;
  }

  public static async hidePost(postId: String): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`hide-post/${postId}`);
    const res = await axios.post<any, AppResponse<any>>(url);
    return res;
  }


}
