import { useState, useRef } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import 'react-advanced-cropper/dist/style.css';
import CropModal from '../components/common/cropModal'

const AddPhotos = ({ photos, setPhotos }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const [croppingImage, setCroppingImage] = useState(null);
  const [cropperRef, setCropperRef] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length === 0) {
      // alert('No file selected. Please choose an image file.');
      return;
    }

    if (photos.length + files.length > 4) {
      console.log('You can only upload a maximum of 4 photos.');
    } else {
      const file = files[0];

      if (file && file instanceof Blob) {
        setCroppingImage(URL.createObjectURL(file));
        setShowModal(true);
      } else {
        console.log('Invalid file. Please select a valid image file.');
      }
    }
  };

  const handleRemovePhoto = (index) => {
    setPhotos((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (photos.length + files.length > 4) {
      alert('You can only upload a maximum of 4 photos.');
    } else {
      const file = files[0];
      setCroppingImage(URL.createObjectURL(file));
      setShowModal(true);
    }
  };

  const handleCropSave = () => {
    if (cropperRef) {
      const croppedCanvas = cropperRef.getCanvas();
      croppedCanvas.toBlob((blob) => {
        setPhotos((prevFiles) => [...prevFiles, blob]);
        setCroppingImage(null);
        setShowModal(false);
      });
    }
  };

  const handleCancelModal = () => {
    setCroppingImage(null);
    setShowModal(false);
  };

  return (
    <>
      <div className='d-flex justify-content-start'>
        <h5 className="card-title pb-3">Add Photos</h5>
      </div>

      <div id="post-modal-data">
        {photos.length < 4 && !croppingImage && (
          <div
            className="mb-4"
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              border: isDragging ? '2px solid #007bff' : '2px dotted #000',
              padding: '20px',
              textAlign: 'center',
              cursor: 'pointer',
              borderRadius: '10px',
              backgroundColor: isDragging ? '#f0f8ff' : 'transparent',
            }}
          >
            <AiOutlineCloudUpload size={50} />
            <p>Drag and drop or click here to upload photos</p>
          </div>
        )}

        <input
          type="file"
          accept="image/*"
          multiple
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={handlePhotoChange}
        />

      {photos.length > 0 && (
        <div className="row g-3">
          {photos.map((file, index) => (
            <div key={index} className="col-6 col-md-4 col-lg-3 d-flex flex-column align-items-center mb-4">
              <div className="position-relative rounded overflow-hidden shadow-sm" style={{ width: '100%', maxWidth: '200px' }}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Photo ${index}`}
                  className="img-fluid rounded pt-2 pb-2"
                  style={{ height: '200px', objectFit: 'cover', width: '100%' }}
                />
                <div className="justify-content-center">
                  <button type="button" class="remove-btn mt-2" style={{ width: '40px', height: '40px' }} onClick={() => handleRemovePhoto(index)}><span class="material-symbols-outlined ">delete</span></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}


      </div>

      
      <CropModal
        show={showModal}
        handleCancelModal={handleCancelModal}
        croppingImage={croppingImage}
        handleCropSave={handleCropSave}
        setCropperRef={setCropperRef}
      />
    </>
  );
};

export default AddPhotos;
