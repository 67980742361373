import React, { useState } from "react";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { introSchema } from "../schemas/introductionSchema";
import { IndividualService } from "../../../services/IndividualService";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { states } from '../../../components/common/optionsData'


// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);


const Introduction = ({ handleNext, formData }) => {

  const [isLoading, setIsLoading] = useState(false)


  const onSubmit = async (values, actions) => {
    const submitData = {
      name: values.name || formData.name,
      lastName: values.lastName || formData.lastName,
      state: values.state,
      dob: values.dob,
      phoneNumber: "+1" + values.phoneNumber,
      gender: values.gender,
    };
    setIsLoading(true); // Start loading
    try {
      const res = await IndividualService.onboardDetails(submitData);

      if (res.success) {
        handleNext(submitData); // Pass submitData to handleNext to update formData
      } else {
        toast.error(res.error, {
          position: "bottom-right"
        });
        if (res.errorCode === 500) {
          handleNext(submitData); // Ensure formData updates even if an error occurs
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "bottom-right"
      });
      console.log("An error occurred", error);
    } finally {
      setIsLoading(false); // Ensure loading state is reset in all cases
    }
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, setFieldValue, handleSubmit } = useFormik({

    initialValues: {
      name: formData.name || "",
      lastName: formData.lastName || "",
      phoneNumber: formData.phoneNumber || "",
      state: formData.state || "",
      dob: formData.dob || "",
      gender: formData.gender || "",
    },
    validationSchema: introSchema,
    onSubmit,
  });

  

  return (
    <>
      <Col md={12}>
        <div>
          <ToastContainer />
        </div>
        <div className="sign-in-from">
          <Link
            to="/onboard"
            className="d-inline-flex align-items-center justify-content-center gap-2"
          >

            <h2 className="logo-title" data-setting="app_name">
              Introduce Yourself
            </h2>
          </Link>
          <Form onSubmit={handleSubmit} className="mt-5">
            <Form.Group className="form-group text-start">
              <label className="form-label fw-500 text-dark">First Name</label>
              <Form.Control
                value={values.name}
                onChange={handleChange}
                id="name"
                type="text"
                placeholder="First Name"
                onBlur={handleBlur}
                className={errors.name && touched.name ? "text-danger form-control" : "form-control"}
              />
              {errors.name && touched.name && <p className="place-order-title" style={{ color: 'red' }}>{errors.name}</p>}
              <div className="mb-3"></div>
              <label className="form-label fw-500 text-dark">Last Name</label>
              <Form.Control
                value={values.lastName}
                onChange={handleChange}
                id="lastName"
                type="text"
                placeholder="Last Name"
                onBlur={handleBlur}
                className={errors.lastName && touched.lastName ? "text-danger form-control" : "form-control"}
              />
              {errors.lastName && touched.lastName && <p className="text-danger place-order-title" style={{ color: 'red' }}>{errors.lastName}</p>}
              <div className="mb-3"></div>
              <label className="form-label fw-500 text-dark">Date of Birth</label>
              <Form.Control
                value={values.dob}
                onChange={handleChange}
                id="dob"
                type="date"
                placeholder="Date of Birth"
                onBlur={handleBlur}
                className={errors.dob && touched.dob ? "input-error form-control" : "form-control"}
              />
              {errors.dob && touched.dob && <p className="text-danger place-order-title" style={{ color: 'red' }}>{errors.dob}</p>}
              <div className="mb-3"></div>
              <label className="form-label fw-500 text-dark">Phone Number</label>
              <Form.Control
                value={values.phoneNumber}
                onChange={handleChange}
                id="phoneNumber"
                type="text"
                placeholder="Phone Number"
                onBlur={handleBlur}
                className={errors.phoneNumber && touched.phoneNumber ? "input-error form-control" : "form-control"}
              />
              {errors.phoneNumber && touched.phoneNumber && <p className="text-danger place-order-title" style={{ color: 'red' }}>{errors.phoneNumber}</p>}
              <div className="mb-3"></div>
              <label className="form-label fw-500 text-dark">State</label>
              <Form.Control
                as="select"
                name="state"
                id="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors.state && touched.state ? " form-control" : "form-control"}
              >
                <option value="">Choose a state</option>
                {states.map((state, id) => (
                  <option key={id} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Form.Control>
              {errors.state && touched.state && <p className="text-danger place-order-title " style={{ color: 'red' }}>{errors.state}</p>}
              <div className="mb-3"></div>
              <label className="form-label fw-500 text-dark" > Gender </label><br />
              <div className="form-check radio form-check-inline form-label fw-500 text-dark">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    value="male"
                    checked={values.gender === "male"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className="custom-radio"
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    value="female"
                    checked={values.gender === "female"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className="custom-radio"
                  />
                  <Form.Check
                    type="radio"
                    label="Other"
                    name="gender"
                    value="other"
                    checked={values.gender === "other"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className="custom-radio"
                  />
                </Form.Group>
              </div>
              <div>{errors.gender && touched.gender && <p className="place-order-title" style={{ color: 'red' }}>{errors.gender}</p>}</div>
            </Form.Group>
          </Form>
          <Button
            className="btn btn-primary w-100 mt-4"
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Submitting...</span>
              </>
            ) : (
              'Next'
            )}
          </Button>
        </div>

      </Col>

    </>
  );
};

export default Introduction;
