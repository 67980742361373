import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";


//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

// import logo from "../../../assets/images/logo-full.png";
import login1 from "../../../assets/images/login/1.jpg";
import login2 from "../../../assets/images/login/2.jpg";
import login3 from "../../../assets/images/login/3.jpg";

// components
import ActiveSubscription from './activesubsription';
import DatingandFriendships from './datingandFriendship';
import Introduction from './introduction';
import SendOtp from './otp';
import TermsandConditions from './termsAndConditions';
import Location from './userLocation'
import ChosePreference from './chosePreference'
import Pets from './pets'
import Aboutme from './aboutMe'
import GetStart from './getStart'
import UploadVideo from "./uploadVideo";
import UploadPhoto from "./uploadPhoto";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Onbording = () => {

  const [show, setShow] = useState('step1');
  const [formData, setFormData] = useState({

    name: "",
    lastName: "",
    phoneNumber: "",
    state: "",
    dob: "",
    gender: "",

    compatiblePreference: '',
    height: 90,
    preferLanguages: [],
    pets: [],
    hobbies: [],

    moreInformation: "",
    aboutWho: "",

  });

  const steps = ['step1', 'step2', 'step3', 'step4', 'step5', 'step6', 'step7', 'step8'];

  const handleNext = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data })); // Update formData with new data
    const currentIndex = steps.indexOf(show);
    if (currentIndex < steps.length - 1) {
      setShow(steps[currentIndex + 1]); // Move to the next step
    }
  };

  useEffect(() => {
    console.log("Updated formData", formData); // Log whenever formData changes
  }, [formData]);


  const components = {

    step1: <Introduction handleNext={handleNext} formData={formData} />,
    step2: <SendOtp handleNext={() => setShow('step3')} handlePrevious={() => setShow('step1')} />,
    step3: <Location handleNext={() => setShow('step4')} handlePrevious={() => setShow('step2')} />,
    step4: <ActiveSubscription handleNext={handleNext} handlePrevious={() => setShow('step3')} />,
    step5: <ChosePreference handleNext={handleNext} formData={formData} handlePrevious={() => setShow('step4')} />,
    step6: <Aboutme handleNext={handleNext} formData={formData} handlePrevious={() => setShow('step5')} />,
    step7: <UploadVideo handleNext={() => setShow('step8')} handlePrevious={() => setShow('step6')} />,
    step8: <GetStart handleNext={() => setShow('')} handlePrevious={() => setShow('')} />,

  }



  return (
    <>
      <section className="sign-in-page">
        <Container fluid>
          <Row className="align-items-center">
            <Col md={6} className="overflow-hidden position-relative">
              <div className="bg-primary w-100 h-100 position-absolute top-0 bottom-0 start-0 end-0"></div>
              <div className="container-inside z-1">
                <div className="main-circle circle-small"></div>
                <div className="main-circle circle-medium"></div>
                <div className="main-circle circle-large"></div>
                <div className="main-circle circle-xlarge"></div>
                <div className="main-circle circle-xxlarge"></div>
              </div>
              <div className="sign-in-detail container-inside-top">
                <Swiper
                  className="list-inline m-0 p-0 "
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                >
                  <ul className="swiper-wrapper list-inline m-0 p-0 ">
                    <SwiperSlide>
                      <img
                        src={login1}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Power UP Your Friendship
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login2}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Connect with the world
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={login3}
                        className="signin-img img-fluid mb-5 rounded-3"
                        alt="images"
                      />
                      <h2 className="mb-3 text-white fw-semibold">
                        Together Is better
                      </h2>
                      <p className="font-size-16 text-white mb-0">
                        It is a long established fact that a reader will be
                        <br /> distracted by the readable content.
                      </p>
                    </SwiperSlide>
                  </ul>
                </Swiper>
              </div>
            </Col>
            <Col md={6} className="overflow-hidden position-relative">
              <Row>
                <Col md={3}></Col>
                {components[show]}
                <Col md={3}></Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  );
};

export default Onbording;
